import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { PageContainer, PageHeading2 } from "../../globalStyles";
import {
  ContentContainer,
  NavContainer,
  NavControls,
  UpdatesList,
  UpdateItem,
  UpdateDate,
} from "./ChangelogPageStyle";

function ChangelogPage() {
  let navigate = useNavigate();

  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleHomeClick = (event) => {
    event.preventDefault();
    navigate(`/`);
  };

  return (
    <PageContainer>
      <NavContainer theme={theme} className={`NavContainer`}>
        <NavControls className="NavControls">
          <h2 onClick={handleHomeClick} style={{ cursor: "pointer" }}>
            <u>Mahābhārata</u>
          </h2>
        </NavControls>
      </NavContainer>

      <ContentContainer className={`ContentContainer navClosed`}>
        <PageHeading2>Technical Changelog</PageHeading2>
        <UpdatesList>
          <UpdateItem>
            <p>
              <UpdateDate>May 31 2024</UpdateDate>: Init. Website deployed.
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <UpdateDate>Jun 01 2024</UpdateDate>: Cosmetic Changes
            </p>
            <ul>
              <li>Changed how links behave on hover: made them bolder</li>
              <li>Removed bullet points from homepage books list</li>
              <li>
                Fixed vertical alignment of chapter heading wings on book
                content pages
              </li>
            </ul>
          </UpdateItem>
          <UpdateItem>
            <p>
              <UpdateDate>Jun 03 2024</UpdateDate>: Removed Buttons, Dark Mode
            </p>
            <ul>
              <li>
                Removed highlighter and eraser buttons from mobile interface, as
                it doesn't work. Will have to fix later
              </li>
              <li>Added global dark mode</li>
            </ul>
          </UpdateItem>
          <UpdateItem>
            <p>
              <UpdateDate>Jun 18 2024</UpdateDate>: Bugfix
            </p>
            <ul>
              <li>
                Pressing the 'Enter' key on the navbar form inputs triggered the
                home button, instead of the submit button. Fixed.
              </li>
            </ul>
          </UpdateItem>
          <UpdateItem>
            <p>
              <UpdateDate>Jun 27 2024</UpdateDate>: Bugfix
            </p>
            <ul>
              <li>Verse navigation was not working. Fixed.</li>
            </ul>
          </UpdateItem>
        </UpdatesList>
      </ContentContainer>
    </PageContainer>
  );
}

export default ChangelogPage;
