import styled, { keyframes } from "styled-components";

const flashAnimation = keyframes`
  0% {
    background-color: white;
  }
  50% {
    background-color: pink;
  }
  100% {
    background-color: white;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;

  margin-top: 110px;

  @media (max-width: 767px) {
    margin-top: ${(props) =>
      props.className === "ContentContainer navOpen" ? "150px" : "80px"};
  }

  transition: all 0.3s ease;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;

  @media (max-width: 767px) {
    height: "40px";
  }

  ${(props) =>
    props.theme === "light"
      ? "background-color: white; box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);"
      : "background-color: black; box-shadow: 0 4px 4px -2px rgba(255, 255, 255, 0.5);"}

  position: fixed;
  top: 0;
  padding: 10px;
  margin-bottom: 10px;

  z-index: 999;

  div.NavControls {
    padding: 10px;
  }

  transition: all 0.3s ease;
`;

export const NavControls = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  margin: 5px;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  margin: 0 10px;

  width: 30%;

  input {
    margin: 2px;
    width: 15px;

    @media (max-width: 767px) {
      width: 25%;
    }

    /* styles for screens between 768px and 1023px */
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 25%;
    }

    /* styles for screens larger than 1023px */
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
`;

export const HomeButton = styled.button`
  /* Button styles */
  padding: 2px 10px;
  border: none;
  background-color: ${({ isOn }) =>
    isOn ? "pink" : "white"}; /* Change color based on isOn state */
  // color:
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;

  /* Hover effect */
  &:hover {
    background-color: lightblue;
  }

  /* Focus effect */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #b4b4b4;
  }

  /* Active effect */
  &:active {
    transform: translateY(1px);
  }

  /* Disabled styles */
  &:disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }

  /* Apply animation when clicked */
  &.flash {
    animation: ${flashAnimation} 0.5s ease;
  }
`;

export const UpdatesList = styled.ul`
  font-family: "Courier Prime", monospace;
  margin-left: 2vw;
`;

export const UpdateItem = styled.li``;

export const UpdateDate = styled.p``;

export const UpdateContent = styled.p``;

export const ProgressBar = styled.span`
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
`;
